<template>
	<div class="grid"  v-if="isshow=fasle">
		<div class="col-12">
			<div class="card border-noround mb-0">
				<div class="flex align-items-center justify-content-between">
					<div>
						<p>
							宝石筛选：
						</p>
						<Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Select" />
					</div>
					<Button label="显示我的列表" 
					class="mr-2 p-button-raised p-button-secondary"></Button>
				</div>
			</div>
		</div>
    </div>
	 <div class="content_title mt-4 mb-4">
        市场武器
    </div>
	<div class="grid" >
		<div class="col-12 flex justify-content-between" >
			 <Button label="刷新武器列表" icon="iconfont icon-jiaose-" 
    			class="mr-2" @click="getListEvent()"></Button>
				<Button :label="showListText" @click="getMyListEvent()"></Button>
		</div>
		<div class="col-12 mt-2">
			品质筛选：
		</div>
		<div class="col-12">
			<Button label="All" class="mr-2" @click="filterArms(0)" :class="filterNum == 0 ?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="1" class="mr-2" @click="filterArms(1)" :class="filterNum==1?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="2" class="mr-2" @click="filterArms(2)" :class="filterNum==2?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="3" class="mr-2" @click="filterArms(3)" :class="filterNum==3?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="4" class="mr-2" @click="filterArms(4)" :class="filterNum==4?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="5" class="mr-2" @click="filterArms(5)" :class="filterNum==5?'active':'p-button-raised p-button-secondary'"></Button>
		</div>
	</div>
	<div class="grid mt-4" v-if="marketListNow">
		<template v-if="isMyList==0">
			<div class="col-12 lg:col-6 xl:col-4" v-for="item,index in marketListNow">
				<div class="card mb-0 text-center MarketCard active">
					<img :src='require("@/assets/img/arm/monkey/"+item.arm.quality.toNumber()+".jpg")' class="cover_img" v-if="item.arm.number.toNumber()==1"/>
						<img :src='require("@/assets/img/arm/pig/"+item.arm.quality.toNumber()+".jpg")' class="cover_img" v-else-if="item.arm.number.toNumber()==2"/>
						<img :src='require("@/assets/img/arm/shazeng/"+item.arm.quality.toNumber()+".jpg")' class="cover_img" v-else-if="item.arm.number.toNumber()==3"/>
					<div class="content">
						<h5 class="title">	{{item.arm.name}}</h5>
						
						<p>品质：{{item.arm.quality.toNumber()}}</p>
						
					</div>
					<div class="flex align-items-center justify-content-between">
						<h5 class="title mb-0 text-left">
							{{MathPrice(Number(ethers.utils.formatEther(item.price,2)))}}
							<span class="text-warning"> {{tokenName}}</span>
								<p style="font-size:small">
								卖家：{{item.sellerAddress.slice(0,5)}}...{{item.sellerAddress.slice(-4)}}
							</p>
						</h5>
						<Button  icon="iconfont icon-gouwucheman" 
						class="p-button-raised p-button-yellow"
						@click="buyArm(item)"	></Button>
			
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<template v-for="item,index in marketListNow">
				<div class="col-12 lg:col-6 xl:col-4" 
				
				v-if="item.sellerAddress==$store.getters.getErc20Address">
						<div class="card mb-0 text-center MarketCard active">
							<img :src='require("@/assets/img/arm/monkey/"+item.arm.quality.toNumber()+".jpg")' class="cover_img" v-if="item.arm.number.toNumber()==1"/>
								<img :src='require("@/assets/img/arm/pig/"+item.arm.quality.toNumber()+".jpg")' class="cover_img" v-else-if="item.arm.number.toNumber()==2"/>
								<img :src='require("@/assets/img/arm/shazeng/"+item.arm.quality.toNumber()+".jpg")' class="cover_img" v-else-if="item.arm.number.toNumber()==3"/>
							<div class="content">
								<h5 class="title">	{{item.arm.name}}</h5>
								
								<p>品质：{{item.arm.quality.toNumber()}}</p>
								
							</div>
							<div class="flex align-items-center justify-content-between">
								<h5 class="title mb-0 text-left">
									{{MathPrice(Number(ethers.utils.formatEther(item.price,2)))}}
							<span class="text-warning"> {{tokenName}}</span>
								<p style="font-size:small">
								卖家：{{item.sellerAddress.slice(0,5)}}...{{item.sellerAddress.slice(-4)}}
							</p></h5>
								<takeDownArmModal :info="item" @getListEvent=getListEvent />
					
							</div>
						</div>
				
				</div>
			</template>
		</template>
	</div>
     
</template>

<script>

import { defineComponent,ref} from "vue";
import BuyModal from "../../components/BuyModal/modal.vue";
import * as LogicMarket from "../../wallet/LogicMarket";
import takeDownArmModal from "../../components/Modal/takeDownArmModal.vue";
export default defineComponent({
	setup() {
		let dropdownValue='';
		let dropdownValues = [
			{name: '一级武器', code: '0'},
			
		]
		let marketList=ref('');
		let marketListNow=ref('');
		let filterNum=ref(0);
		const getListEvent=()=>{
			marketList.value='';
			LogicMarket.getArmsMarketList().then(res=>{
				console.log("获取市场所有武器",res)
				marketList.value=res;
				marketListNow.value=res;
				filterNum.value=0;
			}).catch(error=>{
				console.log("调用方法失败")
			})
		}
		getListEvent();

		//购买武器
		const buyArm=(info)=>{
			
			LogicMarket.buyArms(info.arm.itemId.toNumber(),info.sellerAddress).then(res=>{
				console.log("购买角色详情",res)
				// marketList.value=res;
				getListEvent();

			}).catch(error=>{
				console.log("调用方法失败")
			})
		}

		//筛选武器
		const filterArms=(quality)=>{
			filterNum.value=quality;
			if(quality!=0){
				let filterArmsArr=marketList.value.filter((value,key,arr) => {
					return value.arm.quality.toNumber() == quality? true :  false; 
				})
				marketListNow.value=filterArmsArr;
			}else{
				marketListNow.value=marketList.value;
			}
			
		}
		//筛选出自己
		let showListText=ref("我的上架");
		let isMyList=ref(0);
		const getMyListEvent=(type)=>{
			getListEvent();
			
			
			if(isMyList.value==0){
				isMyList.value=1;
				showListText.value="显示全部";
			}else{
				isMyList.value=0;
				showListText.value="我的上架";
				
			}
			
			
		}
		return{
			marketListNow,
			filterNum,
			filterArms,
			dropdownValue,
			dropdownValues,
			marketList,
			getListEvent,
			buyArm,
			showListText,
			getMyListEvent,
			isMyList,
		}
	},
	 components: {
		 BuyModal,
		 takeDownArmModal
	 }
})
</script>

<style lang="scss" scoped>
.MarketCard {
	img{
	
		height: -webkit-fill-available;
		
	}
}
</style>