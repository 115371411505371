<template>
    <Dialog header="" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true">
        <p class="line-height-3 m-0">
            
        </p>
        <template #footer>
            <Button label="Close" @click="close" icon="pi pi-check" 
            class="p-button-secondary"/>
        </template>
    </Dialog>
</template>

<script>
import { defineComponent,ref } from 'vue';
import Dialog from 'primevue/dialog'; 
export default defineComponent({
    name:"BuyModal",
    setup() {
        let display=ref(true);
        //console.log("tests");

        const close=()=>{
            display.value=false;
            return 
        }
        return {
            close,
            display
        }
        
    },
    components : {
        Dialog
    }
    
})
</script>
